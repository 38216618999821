(function() {
    "use strict";
    var app = angular.module("laekkerAI.tablet");

    app.controller("TabletMainMenuController", [
        "TabletHomeService",
        "TabletConfigService",
        "TabletAuthService",
        "TabletEventService",
        function(homeService, configService, authService, eventService) {
            var $ctrl = this;

            $ctrl.$onInit = function() {
                $ctrl.apps = [];
                $ctrl.extApps = [];

                // $ctrl.apps.push({
                //     statedata: {
                //         state: "kitchen.category-selection.category",
                //         params: { categoryId: 6 }
                //     },
                //     name: "AI Kitchen",
                //     imagePath: "images/kitchen.png"
                // });

                $ctrl.apps.push({
                    path: "/warehouse",
                    name: "Inventur",
                    iconClass: "typcn typcn-th-list-outline"
                });  
                
                $ctrl.apps.push({
                    statedata: {
                        state: "stock-items"
                    },
                    name: "Lager",
                    iconClass: "typcn typcn-th-small-outline"
                });

                // // planning
                // authService.hasPermission("PRODUCTIONPLANNING_ACCESS", function(result) {
                //     if (result.granted === true) {
                //         $ctrl.apps.push({
                //             statedata: {
                //                 state: "kitchen.planning"
                //             },
                //             name: "Planung",
                //             iconClass: "typcn typcn-calendar-outline"
                //         });
                //         // $ctrl.apps.push({
                //         //     statedata: {
                //         //         state: "personal-planning"
                //         //     },
                //         //     name: "Personal",
                //         //     iconClass: "typcn typcn-group-outline"
                //         // });
                //         // $ctrl.apps.push({
                //         //     statedata: {
                //         //         state: "my-city"
                //         //     },
                //         //     name: "Reports",
                //         //     iconClass: "typcn typcn-chart-line-outline"
                //         // });
                //     }
                // });        

                //QM Spot  
                $ctrl.extApps.push({
                    statedata: {
                        state: "qm-spot"
                    },
                    name: "QM Spot",
                    imagePath: "images/qm-spot.svg"
                });

                 
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "FoodNotify",
                //     imagePath: "images/foodnotify.png"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "Rational",
                //     iconClass: "typcn typcn-tabs-outline"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "Espera",
                //     iconClass: "typcn typcn-tabs-outline"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "DocuWare",
                //     iconClass: "typcn typcn-tabs-outline"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "BankettProfi",
                //     imagePath: "images/bankettprofi.png"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "Personio",
                //     iconClass: "typcn typcn-tabs-outline"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "Hubspot",
                //     iconClass: "typcn typcn-tabs-outline"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "Outlook",
                //     iconClass: "typcn typcn-mail"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "SAP Ariba",
                //     iconClass: "typcn typcn-tabs-outline"
                // });
                // $ctrl.extApps.push({
                //     statedata: {
                //         state: "ext-app"
                //     },
                //     name: "Punchout",
                //     iconClass: "typcn typcn-tabs-outline"
                // });

            
                homeService.setHome("auth");
                eventService.broadcast(eventService.events.userLogin);
            };
        }
    ]);
})();

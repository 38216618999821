var __appConfig = {
    api: '//legacy-api.aveato.de/api/',
    microServiceApi: "//planning-api.aveato.de/api/",
    web: '//legacy-api.aveato.de/',
    signalr: '//legacy-api.aveato.de/signalr',
    baseUrl: '//tablet-ui.aveato.de/',
    jobUpdateInterval: 300000,
    preloadRecipes: true
};

// var __appConfig = {
//     api: '//app.aveato.eatatsys.local/api/',
//     microServiceApi: "//api.planning.aveato.eatatsys.local/api/",
//     web: '//app.aveato.eatatsys.local/',
//     signalr: '//app.aveato.eatatsys.local/signalr',
//     baseUrl: '//beta.tablet.aveato.eatatsys.local/',
//     jobUpdateInterval: 300000
// };


// var __appConfig = {
//     api: '//legacy-api.aveato.de/api/',
//     microServiceApi: "//api.planning.aveato.eatatsys.local/api/",
//     web: '//legacy-api.aveato.de/',
//     signalr: '//legacy-api.aveato.de/signalr',
//     baseUrl: '//tablet-ui.aveato.de/',
//     jobUpdateInterval: 300000,
//     preloadRecipes: true
// };